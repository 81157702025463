import VueDropzone from 'vue2-dropzone'
import excelParser from '../../../../services/excel-parser'
import saleRepService from '../../../../services/supplier/sales-rep'
import _ from 'lodash'
export default {
    components: {
        VueDropzone
    },
    data() {
        return {
            item: {},
            listHeader: [],
            isFileSelect: false,

            labelName: {
                firstName: 'First name *',
                lastName: 'Last name *',
                emailAddress: 'Email address *',
                title: 'Title *',
                territory: 'Territory',
                region: 'Region',
                accountName: 'Account Name *',
                accountNumber: 'Account Number *',
                idn: 'IDN/Provider Name *',
            },

            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.$refs.modal.show()
        },

        addSuccess(file) {
            this.isFileSelect = false
            this.item = {
                firstName: null,
                lastName: null,
                emailAddress: null,
                title: null,
                territory: null,
                region: null,
                accountName: null,
                accountNumber: null,
                idn: null,
                file: null
            }

            if (file.name && _.isArray(file.name.split('.'))) {
                var ext = file.name.split('.')
                if (['xls','xlsx'].includes(ext[ext.length - 1])) {
                    this.item.file = file
                    try {
                        let formData = new FormData()
                        formData.append('file', this.item.file)
                        excelParser.getExcelHeaders(formData).then(res => {
                            if (!res.error) {
                                this.listHeader = res.data.d.map(x => { return { value: x, text: x } })
                                this.isFileSelect = true
                            }
                        })
                    } catch (e) {
                        console.clear()
                    }
                } else {
                    this._showToast('Only accept xls, xlsx file format', { variant: 'danger' })
                }
            }
        },

        async save() {
            var isValid = await this.$refs['observer'].validate()
            if (isValid) {
                let formData = new FormData()
                formData.append('firstName', this.item.firstName)
                formData.append('lastName', this.item.lastName)
                formData.append('emailAddress', this.item.emailAddress)
                formData.append('title', this.item.title)
                formData.append('territory', this.item.territory)
                formData.append('region', this.item.region)
                formData.append('accountName', this.item.accountName)
                formData.append('accountNumber', this.item.accountNumber)
                formData.append('iDN', this.item.idn)
                formData.append('file', this.item.file)
                saleRepService.uploadFile(formData).then(res => {
                    if (!res.error) {
                        let message = `Successfully uploaded. ${res.data.d.created} sales rep created, ${res.data.d.updated} sales rep updated.`
                        if (res.data.d.invalidEmails && res.data.d.invalidEmails.length > 0) {
                            message += '<div> Invalid Email Address: </div>'
                            res.data.d.invalidEmails.forEach((email, index) => {
                                message += '<div>' + `${index + 1}. ${email} ` + '</div>'
                            })
                        }
                        this._showImportSalesRep('Upload Summary', message, 'success')
                        this.$emit('onCompleted', 'UPLOAD')
                        this.$refs.modal.hide()
                    }
                })
            }
        },
    }
}