import LocationModal from './SalesRepModal'
import LocationImport from './SalesRepImport'
import locationService from '../../../services/supplier/location'
import saleRepService from '../../../services/supplier/sales-rep'
import { Supplier_Sales_Representative_Headers, AG_GridCustomizedTableHeaders_Key } from '../../../misc/tableHeaders'
export default {
    components: {
        LocationModal,
        LocationImport
    },
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            gridApi: null,
            columnApi: null,
            total: 0,
            items: [],
            status: 'All',
            reportTitle: 'Sales Representatives Report',
            search: {
                idn: null,
                locationId: null,
                locationName: null,
                address1: null,
                address2: null,
                state: null,
                city: null,
                zipCode: null,
                notisphereId: null,
                verified: null,
                status: null
            },
            locationModalKey: 0,
            locationImportKey: 0,
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    beforeMount() {
        this.columnDefs = Supplier_Sales_Representative_Headers
    },
    created() {
        this.rowModelType = 'serverSide'
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Supplier_Login.Location_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Supplier_Login?.Location_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onCellClicked(params) {
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'inactive' || params.event.target.dataset.action === 'active') {
                    this.changeStatusForSalesRep(params.data, params.event.target.dataset.action)
                }
                if (params.event.target.dataset.action === 'edit') {
                    this.updateLocation(params.data, 'UPDATE')
                }
                return
            }else{
                this.updateLocation(params.data, 'VIEW')
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Supplier_Login.Location_Filters = event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Supplier_Login?.Location_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            if (type == 'UPLOAD') {
                this._showToast('Successfully uploaded!')
            }
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
        },
        addMoreClick() {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderInsert()
            })
        },
        updateLocation(payload, mode) {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderUpdate(payload.id,mode)
            })
        },
        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            const datasource = {
                getRows(params) {
                    that.search.idn = params.request?.filterModel?.idn?.filter
                    that.search.email_address = params.request?.filterModel?.email_address?.filter
                    that.search.first_name = params.request?.filterModel?.first_name?.filter
                    that.search.last_name = params.request?.filterModel?.last_name?.filter
                    that.search.title = params.request?.filterModel?.title?.filter
                    that.search.territory = params.request?.filterModel?.territory?.filter
                    that.search.region = params.request?.filterModel?.region?.filter
                    that.search.status = params.request?.filterModel?.status?.filter
                    that.search.account_number = params.request?.filterModel?.account_number?.filter
                    that.search.account_name = params.request?.filterModel?.account_name?.filter
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    saleRepService.findSaleRep({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search
                    }).then(resp => {
                        if (!resp.error) {
                            that.total = resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return resp.data.d.c
                        }
                    }).then(response => {
                        params.successCallback(response)
                    }).catch(error => {
                        console.error(error)
                        params.failCallback()
                    })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)
        },
        downloadsuppliersalesrepReport(ext) {
            saleRepService.downloadSupplierLocationReportReport(ext)
        },
        changeStatusForSalesRep(item, status) {
            saleRepService.changeStatusForSalesRep(item.id, status).then(resp => {
                if (!resp.error) {
                    if (item.status=='ACTIVE' ) {
                        this._showToast('Sales Rep is Inactivated successfully!')
                    } else {
                        this._showToast('Sales Rep is Activated successfully!')
                    }
                    this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
                }
            })
        },
        importFile() {
            this.locationImportKey += 1
            this.$nextTick(() => {
                this.$refs.locationImport.forceRerenderInsert()
            })
        },
        downloadRecallSummaryReport(ext) {
            locationService.downloadSupplierLocationReportReport(ext)
        },
    }
}